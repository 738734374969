import React from 'react';
import styled from 'styled-components';
import { theme } from 'style';
import { dateIsPassed } from 'utils';

type LogisticsActionLinkContainerProps = {
  completedOrderCount: number;
  orderCount: number;
  date: string;
};

type ContainerProps = {
  backgroundColor: string;
  borderColor: string;
};

const LinkContainerStyle = styled.div<ContainerProps>`
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  position: relative;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: ${theme.borderRadius.base}px;
  padding: 5px 10px;
  margin-bottom: 20px;
`;

const getStyle = (completed: boolean, hasStarted: boolean, date: string) => {
  if (completed)
    return {
      backgroundColor: theme.palette.available,
      borderColor: theme.palette.available
    };

  if (dateIsPassed(date))
    return {
      backgroundColor: theme.palette.error,
      borderColor: theme.palette.error
    };

  if (hasStarted)
    return {
      backgroundColor: theme.palette.warning,
      borderColor: theme.palette.warning
    };

  return {
    backgroundColor: 'transparent',
    borderColor: theme.palette.black
  };
};

const LogisticsActionLinkContainer: React.FC<LogisticsActionLinkContainerProps> = ({
  completedOrderCount,
  orderCount,
  date,
  children
}) => {
  const completed =
    completedOrderCount === orderCount && completedOrderCount > 0;

  const hasStarted = completedOrderCount > 0;

  const { backgroundColor, borderColor } = getStyle(
    completed,
    hasStarted,
    date
  );

  return (
    <LinkContainerStyle
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      {children}
    </LinkContainerStyle>
  );
};

export default LogisticsActionLinkContainer;
