import React from 'react';
import { LogisticsActionInfo } from 'types';

type Props = {
  infoTexts: LogisticsActionInfo;
};

const LogisticsActionLinkContent = ({ infoTexts }: Props) => {
  const {
    bookingNumberText,
    fromToText,
    withText,
    statusText,
    trackingNumberText
  } = infoTexts;
  return (
    <>
      <p className="bold">{bookingNumberText}</p>
      <p>{fromToText}</p>
      <p>{withText}</p>
      <p>{trackingNumberText}</p>
      <p className="bold">{statusText}</p>
    </>
  );
};

export default LogisticsActionLinkContent;
